$zoom: 1.44;

$basic-font: 'OpenSans', Helvetica, Arial, sans-serif;
$secondary-font: 'ProximaNova', sans-serif;

$white:#FFFFFF;
$black: #000000;
$blue: #7401FC;
$light-blue: #C7C5F5;
$slider-gray: #414045;
$sub-title-gray: #65656A;
$background-footer: #646262;
$footer-gray: #909095;