@import 'styles/variables';
@import 'styles/extensions';

.main-title {
  background-repeat: no-repeat, no-repeat;
  background-position: 0 0, 70% 15vh;
  background-size: 100%, 100vw;
  box-shadow: inset 0 -62px 83px -8px #000000;

  @media screen and (max-width: 767px) {
    background-position: 100%, 100% 30%;
    background-size: 100%, 250vw;
  }

  @media screen and (max-width: 475px) {
    background-position: 100%, 100% 20%;
  }
  
  &__wrapper {
    @extend %wrapper;
    padding-top: 180px;

    @media (min-width: 2560px) {
      max-width: calc(1280px * #{$zoom});
      padding-top: calc(200px * #{$zoom});
    }
  }

  h1 {
    position: relative;
    z-index: 100;
    padding-bottom: 40px;
    color: $white;
    font-weight: normal;
    font-size: 96px;
    font-family: $secondary-font;
    line-height: 0.5;
    letter-spacing: 0px;
    text-align: left;
    text-transform: uppercase;

    span {
      color: $blue;
    }

    @media (min-width: 2560px) {
      font-size: calc(96px * #{$zoom});
    }

    @media screen and (max-width: 1200px) {
      font-size: 7vw;
      line-height: 0.60;
    }

    @media screen and (max-width: 768px) {
      font-size: 10vw;
      line-height: 0.5;
    }

    @media screen and (max-width: 450px) {
      padding-bottom: 40px;
      font-size: 11vw;
      line-height: 0.65;
    }
  }

  &__small {
    color: $white !important;
    font-size: 44px;
    line-height: normal;

    @media (min-width: 2560px) {
      font-size: calc(44px * #{$zoom});
    }

    @media screen and (max-width: 1200px) {
      font-size: 4vw;
    }

    @media screen and (max-width: 450px) {
      font-size: 5vw;
    }
  }

  &__heading {
    position: relative;
    z-index: 0;
    display: flex;
    justify-content: space-between;
    height: 100%;

    @media screen and (max-width: 767px) {
      flex-direction: column-reverse;
    }
  }
  
  &__description {
    position: relative;
    flex-basis: 50%;
    max-width: 565px;
    margin-top: 60px;
    font-weight: 600;
    font-size: 16px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0px;
    text-align: left;
    
    @media (min-width: 2560px) {
      max-width: calc(600px * #{$zoom});
      font-size: calc(16px * #{$zoom});
      line-height: calc(24px * #{$zoom});
    }

    @media screen and (max-width: 450px) {
      font-size: 16px;
      line-height: 22px;
    }

    &::before {
      position: absolute;
      top: -20px;
      left: 0;
      width: 0;
      height: 2px;
      background: $white;
      content: '';
      transition: all 0.3s ease-in-out;
    }

    strong {
      font-weight: normal;
      font-size: 24px;
      font-family: $secondary-font;
    }

    &.visible {
      &::before {
        width: 125px;
      }
    }

    &_colored-text
    &_link {
      color: $blue;
      font-size: 22px;

      @media (min-width: 2560px) {
        max-width: calc(600px * #{$zoom});
        font-size: calc(20px * #{$zoom});
        line-height: calc(22px * #{$zoom});
      }
  
      @media screen and (max-width: 450px) {
        font-size: 18px;
        line-height: 24px;
      }
    }
  }

  &__sub-description {
    position: relative;
    margin-top: 100px;
    font-size: 16px;

    &::before {
      position: absolute;
      top: -20px;
      left: 0;
      width: 0;
      height: 2px;
      background: $white;
      content: '';
      transition: all 0.3s ease-in-out;
    }

    &.visible {
      &::before {
        width: 125px;
      }
    }

    span {
      color: $light-blue;
    }
  }

  &__inview {
    flex-basis: 65%;
    margin-top: -45%;
    margin-right: -15%;
    
    @media (min-width: 2560px) {
      flex-basis: 74%;
      margin-top: -50%;
      margin-right: -20vw;
    }
  }

  &__video-content {
    position: relative;
    z-index: -5;
  }

  &__hiro-content {
    position: relative;

    img {
      width: 100%;
    }
  }

  &__cta {
    @extend %button-colored;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 370px;
    height: 100%;
    min-height: 64px;
    margin: 20px 0 50px;
    padding: 0;
    color: $white;
    font-size: 18px;
    line-height: 1.3;
    background: linear-gradient(90deg, $blue 0%, $blue 100%);

    &:hover {
      color: $blue;
      background: linear-gradient(90deg, $white 0%, $white 100%);
    }
  }
}